<template>
  <div>
    <v-banner> DETAIL UNIT KERJA {{ $route.params.id }} </v-banner>

    <div class="mt-5">
      <v-card class="pa-3">
        <v-row>
          <v-col cols="12" md="5" class="my-auto">
            <v-img src="@/assets/gambar/no_pic_unitkerja.png"></v-img>
          </v-col>

          <v-col cols="12" md="5">
            <v-card color="grey lighten-3" flat class="pa-2">
              <v-row dense>
                <v-col cols="12" md="3">
                  <span class="caption"> TOTAL PNS </span>
                  <h3>40</h3>
                </v-col>
                <v-col cols="12" md="3">
                  <span class="caption"> TOTAL PPPK </span>
                  <h3>7</h3>
                </v-col>
                <v-col cols="12" md="3">
                  <span class="caption"> TOTAL Non-ASN </span>
                  <h3>12</h3>
                </v-col>
                <v-col cols="12" md="3">
                  <div>
                    <v-icon left>mdi-gender-male</v-icon>
                    <span>20</span>
                  </div>
                  <div>
                    <v-icon left>mdi-gender-female</v-icon>
                    <span>12</span>
                  </div>
                </v-col>
              </v-row>
            </v-card>

            <v-card color="grey lighten-3" flat class="pa-2 mt-1">
              <v-row dense>
                <span class="font-weight-black">Total: 41 Aparatur</span>
              </v-row>
            </v-card>

            <v-card color="light-blue lighten-5" flat class="pa-2 my-3">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="3" class="text-center my-auto">
                      <v-icon size="60">mdi-home-circle</v-icon>
                    </v-col>
                    <v-col cols="12" md="9">
                      <span>Memiliki:</span>
                      <h3>0 Sub Unit Kerja</h3>
                      <v-btn x-small depressed>Detail</v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="3" class="text-center my-auto">
                      <v-icon size="60">mdi-home-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="12" md="9">
                      <span>Memiliki:</span>
                      <h3>0 Sub Unor</h3>
                      <v-btn x-small depressed>Detail</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>

            <v-card flat class="pa-2 mt-1">
              <v-row dense>
                <v-col cols="12" md="3" class="my-auto">
                  <v-img
                    src="https://cdn.vuetifyjs.com/images/john.jpg"
                    alt="image"
                  />
                </v-col>
                <v-col cols="12" md="9">
                  <span>Pimpinan:</span>
                  <div>
                    <h4>Supratman Nento, S.IP., M.H</h4>
                    <span><i>Pembina Utama Muda - IV</i></span> <br />
                    <span>5552221 | 19231xxxxx</span>
                    <h6 class="caption">
                      Menjabat Sejak Tanggal 6 Desember 2021
                    </h6>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" md="2">
            <v-card color="yellow lighten-4" flat class="pa-3">
              <v-row dense>
                <div>
                  <v-icon left>mdi-map-marker-radius</v-icon>
                  <span class="font-weight-black">Alamat</span>
                </div>
                <div class="caption mt-1">
                  Jl. Jend. Sudirman, Blok Perkantoran Marisa, Desa Palopo,
                  Kecamatan Marisa, Palopo, Kec. Marisa, Kabupaten Pohuwato,
                  Gorontalo 96266
                </div>

                <div class="font-weight-black caption mt-2">
                  <v-icon left small>mdi-phone-classic</v-icon>
                  <span class="caption">Telepon</span> <br />
                  <span class="font-weight-black caption"> (0443) 210433 </span>
                </div>

                <div class="mt-2">
                  <v-icon left small>mdi-email</v-icon>
                  <span class="caption">Email</span>
                  <span class="font-weight-black caption">
                    bkppdpohuwato@gmail.com
                  </span>
                </div>

                <div class="font-weight-black mt-2">
                  <span class="caption">Kode Unit Kerja:</span> <br />
                  <h4>772211</h4>
                </div>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="mt-5">
        <v-col cols="12" md="4">
          <v-card class="px-2" outlined>
            <v-card-title class="subtitle-2">
              <v-icon left color="primary">mdi-chart-box</v-icon>
              Jumlah PNS Dalam Jabatan
            </v-card-title>

            <v-row dense class="align-center justify-center">
              <v-col cols="12">
                <v-card class="pa-2 mb-3" flat color="light-blue lighten-5">
                  <span class="caption"> Pimpinan Tinggi</span>
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-row dense>
                        <v-col cols="12" md="6">
                          <v-icon left size="50">mdi-account-box</v-icon>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <h1>1</h1>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col cols="12" md="6" class="my-auto">
                      <v-row dense>
                        <v-col>
                          <v-icon left>mdi-gender-male</v-icon>
                          12
                        </v-col>
                        <v-col>
                          <v-icon left>mdi-gender-female</v-icon>
                          9
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card class="pa-2 mb-3" flat color="light-blue lighten-5">
                  <span class="caption"> Administrator </span>
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-row dense>
                        <v-col cols="12" md="6">
                          <v-icon left size="50">mdi-account-box</v-icon>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <h1>1</h1>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col cols="12" md="6" class="my-auto">
                      <v-row dense>
                        <v-col>
                          <v-icon left>mdi-gender-male</v-icon>
                          12
                        </v-col>
                        <v-col>
                          <v-icon left>mdi-gender-female</v-icon>
                          9
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card class="pa-2 mb-3" flat color="light-blue lighten-5">
                  <span class="caption"> Pengawas </span>
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-row dense>
                        <v-col cols="12" md="6">
                          <v-icon left size="50">mdi-account-box</v-icon>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <h1>1</h1>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col cols="12" md="6" class="my-auto">
                      <v-row dense>
                        <v-col>
                          <v-icon left>mdi-gender-male</v-icon>
                          12
                        </v-col>
                        <v-col>
                          <v-icon left>mdi-gender-female</v-icon>
                          9
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card class="pa-2 mb-3" flat color="light-blue lighten-5">
                  <span class="caption"> Pelaksana </span>
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-row dense>
                        <v-col cols="12" md="6">
                          <v-icon left size="50">mdi-account-box</v-icon>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <h1>1</h1>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col cols="12" md="6" class="my-auto">
                      <v-row dense>
                        <v-col>
                          <v-icon left>mdi-gender-male</v-icon>
                          12
                        </v-col>
                        <v-col>
                          <v-icon left>mdi-gender-female</v-icon>
                          9
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card class="pa-2 mb-3" flat color="light-blue lighten-5">
                  <span class="caption"> Fungsional </span>
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-row dense>
                        <v-col cols="12" md="6">
                          <v-icon left size="50">mdi-account-box</v-icon>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <h1>1</h1>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col cols="12" md="6" class="my-auto">
                      <v-row dense>
                        <v-col>
                          <v-icon left>mdi-gender-male</v-icon>
                          12
                        </v-col>
                        <v-col>
                          <v-icon left>mdi-gender-female</v-icon>
                          9
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="8">
          <v-card class="px-2 pb-2" outlined>
            <v-card-title class="subtitle-2">
              Kondisi Lamanya Aparatur Bertugas Di Unit Ini
            </v-card-title>

            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <v-icon left>mdi-clock</v-icon>
                      &lt; 5 Tahun
                    </td>
                    <td>
                      <v-icon left>mdi-gender-male</v-icon>
                      12
                    </td>
                    <td>
                      <v-icon left>mdi-gender-female</v-icon>
                      10
                    </td>
                    <td>
                      <v-icon left>mdi-gender-male</v-icon>
                      Jumlah : 22
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon left>mdi-clock</v-icon>
                      5 - 9 Tahun
                    </td>
                    <td>
                      <v-icon left>mdi-gender-male</v-icon>
                      12
                    </td>
                    <td>
                      <v-icon left>mdi-gender-female</v-icon>
                      10
                    </td>
                    <td>
                      <v-icon left>mdi-gender-male</v-icon>
                      Jumlah : 22
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon left>mdi-clock</v-icon>
                      10 - 14 Tahun
                    </td>
                    <td>
                      <v-icon left>mdi-gender-male</v-icon>
                      12
                    </td>
                    <td>
                      <v-icon left>mdi-gender-female</v-icon>
                      10
                    </td>
                    <td>
                      <v-icon left>mdi-gender-male</v-icon>
                      Jumlah : 22
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon left>mdi-clock</v-icon>
                      &gt; 15 Tahun
                    </td>
                    <td>
                      <v-icon left>mdi-gender-male</v-icon>
                      12
                    </td>
                    <td>
                      <v-icon left>mdi-gender-female</v-icon>
                      10
                    </td>
                    <td>
                      <v-icon left>mdi-gender-male</v-icon>
                      Jumlah : 22
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  mounted() {},

  methods: {},
};
</script>